<template>
  <div>
    <div id="eleven-exchange">
      <div>
        <img id="title-desktop" :src="'./eleven-exchange-desktop.png'" />
        <img id="title-mobile" :src="'./eleven-exchange-mobile.png'" />
      </div>
    </div>
    <div id="logo" @click="showModal = true" title="Click to enter">
      <div class="glow-wrap">
        <div class="glow" v-bind:style="{ maskImage: 'url(./logo.png)' }">&nbsp;</div>
        <img :src="'./logo.png'" alt="Click to enter" />
      </div>
    </div>
    <div id="footer">
      <div>
        <a @click="showTerms = true">Terms and Conditions</a>
      </div>
      <div>
        <a href="mailto:11exchange@sazerac.com">Contact</a>
      </div>
    </div>
  </div>
  <div class="modal" v-if="showModal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <h2>
            Payment Options
          </h2>
          <div id="buttons">
            <button @click="launchModal('cc')">
              Credit Card
            </button>
            <button @click="launchModal('ach')">
              ACH
            </button>
          </div>
          <div>
            <button @click="showModal = false">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal" v-if="showTerms">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="terms-container">
          <h4>11 Exchange Membership Terms & Conditions</h4>
          <div class="close-button">
            <img :src="'./close.png'" alt="close" @click="showTerms = false">
          </div>
          <ol>
            <li>
              Membership to 11 Exchange is by invitation only. All decisions regarding 11 Exchange membership are made by
              Sazerac Company, Inc. (“Sazerac”) in its sole discretion. Membership invitations are non-transferrable and
              invitations may be rescinded by Sazerac at any time.
            </li>
            <li>
              To become a member, you will be required pay an annual premium (the “Annual Premium”). The Annual Premium is
              currently Fifty Thousand Dollars ($50,000.00), and is subject to change in the future upon notice from
              Sazerac.
            </li>
            <li>
              The Annual Premium is due within 30 days of invitation. Membership begins upon Sazerac's receipt and
              acceptance of your payment of the Annual Premium and expires 1 calendar year later.
            </li>
            <li>
              Once you have paid the Annual Premium, you will be entitled to the following benefits (collectively, the
              “Annual Benefits”):
              <ol type="a">
                <li>Receipt of one member-only, rare bottle from Sazerac's global portfolio. The specific brand, size and
                  product will be determined by Sazerac (the “Annual Bottle”). See section 7 below for additional details.
                </li>
                <li>The opportunity to select single barrels from dedicated inventory for personal use or special
                  occasions. (Barrels are not included in Annual Fee and must be separately purchased.) </li>
                <li>The opportunity to craft a bottle of your own spirit through a curated tasting with Sazerac experts.
                </li>
                <li>The opportunity to have one personal tasting event per year with Sazerac experts leading a private
                  tasting at a location of your choice (subject to applicable laws). Sampling party size is subject to
                  Sazerac's prior approval. Additional charge may be required for large parties. </li>
                <li>Private Distillery Access & Experiences with private, member-only amenities and distillery experts
                  during visits. </li>
                <li>Dedicated Concierge providing personalized support for visit planning, product access, and general
                  inquiries.</li>
              </ol>
              All times and dates of Annual Benefits are on a first come, first served basis, and are subject to
              availability. Advance reservations are required.
            </li>
            <li>At the end of each year, Sazerac may offer you the opportunity to renew your 11 Exchange membership, and
              pay the then current Annual Premium for the upcoming year. All renewals must be completed and paid for prior
              to the beginning of the next membership period. Sazerac will provide you with renewal information and
              instructions in advance of that date. In the event that you do not complete your renewal and pay the Annual
              Premium prior to the beginning of the next membership period, your membership may be cancelled, and you may
              only rejoin 11 Exchange by invitation from Sazerac.</li>
            <li>The Annual Fee is non-refundable. If your membership is cancelled or terminated for any reason no refunds
              will be given. </li>
            <li>The type and size of each Annual Bottle will be in Sazerac's sole discretion. Sazerac will notify you when
              an Annual Bottle becomes available and will make arrangements with you to receive it. </li>
            <li>The sale and resale of alcoholic beverages in the United States is highly regulated and is not permitted
              without the appropriate state licensure. There are also various laws and regulations regarding charity
              donations and gifts of alcoholic beverages. Accordingly, Members are required to ensure that any disposition
              of alcoholic beverage products is done in compliance with all federal and state laws and regulations, and
              with all requisite licenses or permits. </li>
            <li>Members and their guests must refrain from any Prohibited Conduct, including, without limitation:
              <ol type="a">
                <li>Selling or re-selling an Annual Bottle or other Sazerac alcoholic beverage products on any secondary
                  market or in any other channels outside of the “three-tier” system.</li>
                <li>Creating or posting any inappropriate comments or content (as determined by Sazerac, in its sole
                  discretion) on any Sazerac website or social media pages.</li>
                <li>Using illicit drugs or other prohibited substances on any Sazerac property or violating any other
                  distillery visit rules. </li>
                <li>Taking any other actions, or making any statements, which, in Sazerac's sole opinion, may disparage 11
                  Exchange, Sazerac, its brands, products, facilities or employees, or otherwise acting or engaging in any
                  conduct which, in Sazerac's sole opinion, is likely to injure Sazerac or 11 Exchange's public
                  reputation. </li>
              </ol>
              (collectively, the “Prohibited Conduct”)
            </li>
            <li>Sazerac reserves the right to terminate your membership if you or any of your guests engage in any
              Prohibited Conduct or for any other violation of these Terms & Conditions.</li>
            <li>Members may bring guests with them to Sazerac Homeplaces and select 11 Exchange Experiences and Events as
              deemed appropriate by the 11 Exchange Concierge. The number of guests is subject to Sazerac's discretion.
              All guests must be accompanied by a member and all guests must abide by these Terms & Conditions. Guests are
              not entitled to receive any 11 Exchange benefits. </li>
            <li>You agree that Sazerac may from time to time communicate with you about 11 Exchange or other Sazerac
              brands or products via phone call, text, email or other means. Sazerac will not share your non-public
              information with any third parties without your prior consent. By participating in 11 Exchange you agree to
              be bound by Sazerac's <a href="https://www.sazerac.com/terms-and-conditions.html" target="_blank">Terms of
                Use</a> and <a href="https://www.sazerac.com/footer-pages/privacy-policy.html" target="_blank">Privacy
                Policy.</a> </li>
            <li>Members are responsible for any applicable taxes in conjunction with the Annual Fee. </li>
            <li>These Membership Terms & Conditions and Annual Benefits may be modified at any time in Sazerac's sole
              discretion, and Sazerac will notify you of any changes. These Membership Terms & Conditions, and your
              participation in 11 Exchange are governed by the laws of the Commonwealth of Kentucky. Sazerac may choose to
              end 11 Exchange at any time and for any reason, upon notice to you. </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
  <div class="modal" v-if="showSuccess">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-wrapper modal-container-white">
          <h3>Thank you, and welcome to 11 Exchange.</h3>
          <p>Amount: $50,000 USD</p>
          <p>Transaction ID: {{ confirmation }}</p>
          <button @click="showSuccess = false">Ok</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

const options = {
  frame: document.getElementById('id_payWithConvergeFrame'),
  cssOverride: "form ng-include > div:first-of-type {display: none;} " +
    "div.hp-card-brands {display: block !important}"
};

export default {
  name: 'App',
  data() {
    return {
      token: '',
      showModal: false,
      showTerms: false,
      showSuccess: false,
      confirmation: ''
    }
  },
  methods: {
    launchModal(type) {
      axios
        .post(process.env.VUE_APP_API_ENDPOINT, { 'type': type })
        .then((response) => {
          // console.log(`this is the token: ${response.data}`)
          this.token = response.data
          window.PayWithConverge?.open({ "ssl_txn_auth_token": response.data },
            {
              onError: function (error) {
                console.log("error", error);
              },
              onCancelled: function () {
                console.log("cancelled", "");
              },
              onDeclined: function (response) {
                console.log("declined", JSON.stringify(response, null, '\t'));
              },
              onApproval: response => {
                console.log("approval", JSON.stringify(response, null, '\t'));
                this.showModal = false;
                this.confirmation = response.ssl_approval_code;
                this.showSuccess = true;
              }
            }, options)
        })
        .catch((error) => {
          console.log(error)
          this.errorMsg = 'Error retrieving data'
        })
    },
  },
}
</script>

<style>
body {
  background-color: #000000;
  text-align: center;
  display: flex;
  justify-content: center;
  margin: auto;
  height: 100vh;
}

h2 {
  color: #ffffff;
  font-family: 'TT Ramillas W01 Regular', sans-serif;
  margin-bottom: 0;
}

h3 {
  font-family: 'TT Ramillas W01 Regular', sans-serif;
  margin: 0 auto;
  padding-bottom: 20px;
}

h4 {
  font-family: 'TT Ramillas W01 Regular', sans-serif;
  margin: 0 auto;
  font-size: 26px;
  padding-bottom: 20px;
}

p {
  font-family: "tt-commons-pro", sans-serif;
  margin: 5px 0;
}

ol {
  text-align: left;
}

ol[type='a'] {
  list-style-type: lower-alpha;
}

li {
  line-height: 2;
}

#app {
  max-width: 3000px;
  margin: auto;
}

a {
  color: #beb17b;
  font-family: "tt-commons-pro", sans-serif;
  font-size: 1em;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-decoration: none;
  transition: all .25s;
  cursor: pointer;
}


button {
  font-family: "tt-commons-pro", sans-serif;
  border: none;
  display: inline-block;
  padding: 1rem 2rem;
  margin: 0;
  text-decoration: none;
  color: #CCBD86;
  background: transparent;
  font-size: 1rem;
  cursor: pointer;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;
}

a:hover,
button:hover {
  color: #FFFFFF;
}

#buttons button {
  font-family: "TT Ramillas W01 Regular", sans-serif;
  background: #000000;
  border: 1px solid #CCBD86;
  transition: background 250ms ease-in-out,
    transform 150ms ease;
}

#buttons button:hover,
#buttons button:focus {
  background: rgba(204, 189, 134, 0.6);
}

#buttons button:active {
  transform: scale(0.99);
}

.glow-wrap {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.glow {
  /* Positioning */
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: 999;

  /* Mask properties */
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: cover;
  mask-repeat: no-repeat;
  mask-size: cover;
}

.glow:before {
  content: "";
  z-index: 10;
  position: absolute;
  height: 200%;
  width: 200%;
  top: -120%;
  left: -120%;
  background: linear-gradient(transparent 0%,
      rgba(255, 255, 255, 0.1) 45%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0.1) 55%,
      transparent 100%);
  transition: all 1s;
  transform: rotate(-45deg);
  animation: shine 8000ms infinite forwards;
}

@keyframes shine {
  0% {
    top: -120%;
    left: -120%;
  }

  100% {
    left: 100%;
    top: 100%;
  }
}

#logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  cursor: pointer;
  height: 275px;
  position: relative;
}

#logo img {
  text-align: center;
  display: block;
  min-width: 8%;
  margin: 0 auto;
  width: 100px;
  cursor: pointer;
  transition: all .4s;
}

#logo:hover img {
  transform: scale(0.95);
  opacity: .8;
}

#logo:hover .glow {
  transform: scale(0.95);
}

#eleven-exchange {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: auto;
}

#eleven-exchange>div,
#eleven-exchange img {
  max-width: 90%;
}

@media (min-width: 900px) {
  #logo {
    margin: 50px auto 75px;
    height: 150px;
  }

  #logo img {
    width: 67px;
  }

  #eleven-exchange>div,
  #eleven-exchange img {
    max-width: 830px;
  }
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: flex;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.modal-container {
  width: 100%;
  margin: 0px auto;
  padding: 20px 30px;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.modal-container-white {
  max-height: 80%;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, .2), 0 13px 19px 2px rgba(0, 0, 0, .14), 0 5px 24px 4px rgba(0, 0, 0, .12);
  padding: 20px;
  height: 164px;
}

.modal-container-white button {
  font-family: "TT Ramillas W01 Regular", sans-serif;
  border: 2px solid;
  padding: 0 20px;
  border-color: #b69f69;
  color: #b69f69;
  line-height: 32px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .26);
  margin-top: 20px;
  transition: background 250ms ease-in-out,
    transform 150ms ease;
}

.modal-container-white button:hover,
.modal-container-white button:focus {
  background: rgba(204, 189, 134, 0.6);
  color: #FFFFFF;
}

.terms-container {
  font-family: "tt-commons-pro", sans-serif;
  background-color: #FFFFFF;
  width: 90%;
  margin: 0px auto;
  padding: 50px;
  color: #000000;
  overflow: auto;
  height: 400px;
  position: relative;
}

.close-button {
  width: 25px;
  height: 25px;
  position: absolute;
  top: 25px;
  right: 25px;
  cursor: pointer;
}
.close-button img {
  width: 25px;
  height: 25px;
}

.terms-container a {
  font-size: 16px;
}

.terms-container a:focus,
.terms-container a:hover {
  color: #000000;
}

#buttons {
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin: 0px auto;
}

@media (min-width: 40em) {
  #buttons {
    flex-direction: row;
  }

  a {
    font-size: 1.5em;
  }

  #title-mobile {
    display: none;
  }
}

@media (max-width: 40em) {
  #title-desktop {
    display: none;
  }
}

@font-face {
  font-family: "TT Ramillas W01 Regular";
  src: url("Fonts/6107243/8022bd13-00df-4ab5-81a4-3d262f73b0db.woff2") format("woff2"), url("Fonts/6107243/d8a28129-f949-480b-9dca-6ff2ade9c9c3.woff") format("woff");
}
</style>
